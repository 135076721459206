<template>
  <div class="newSeeds">
    <div class="titles">烘干记录</div>
    <div class="title">
      <div class="title-top">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-circle-plus-outline"
          @click="addMessage"
          >新增烘干</el-button
        >
        <div class="el-title-item">
          <el-form ref="form" :model="logisticsForm" label-width="80px">
            <el-form-item label="烘干名称">
              <el-input
                v-model="logisticsForm.name"
                placeholder="请输入关键词"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="title-bottom">
        <div class="title-bottom-left">
          <el-button @click="refresh" icon="el-icon-refresh-right" size="small"
            >刷新</el-button
          >
        </div>
        <div class="title-bottom-right">
          <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="search"
            >搜索</el-button
          >
          <el-button icon="el-icon-refresh" size="small" @click="removeForm"
            >重置</el-button
          >
        </div>
      </div>
    </div>

    <el-table
      v-loading="loading"
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
    >
      <el-table-column prop="id" label="序号" align="center"> </el-table-column>

      <el-table-column
        prop="dryingName"
        label="烘干名称"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>

      <el-table-column
        prop="plantBatchNum"
        label="采购批次"
        show-overflow-tooltip
        align="center"
        :formatter="getFarmName"
      >
      </el-table-column>

      <el-table-column
        prop="count"
        label="数量"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="countUnit"
        label="数量单位"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="notekeeper"
        label="记录人"
        show-overflow-tooltip
        align="center"
        :formatter="getTakerName"
      ></el-table-column>

      <el-table-column
        prop="writeTime"
        label="记录时间"
        show-overflow-tooltip
        align="center"
      ></el-table-column>

      <el-table-column
        prop="remark"
        label="备注"
        show-overflow-tooltip
        align="center"
      ></el-table-column>

      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <div class="operation">
            <el-button
              @click="updateClick(scope.row)"
              type="primary"
              size="small"
              icon="el-icon-edit"
            ></el-button>
            <el-button
              @click="delList(scope.row)"
              type="primary"
              size="small"
              icon="el-icon-delete"
            ></el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="block pagingBox pagesizeBox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 50, 100, 150, 200]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="listTotal"
      >
      </el-pagination>
    </div>
    <!-- 新增 -->
    <el-dialog
      title=""
      :visible.sync="addupdateForm"
      width="31vw"
      class="addAlameBox"
    >
      <div slot="title" class="header-title" style="position: relative">
        <span class="title-name"></span>
        <span class="title-age">{{ boxPrompt }}</span>
        <span
          style="
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0px;
            font-size: 2.22vh;
          "
        >
        </span>
      </div>
      <div class="addAlaForm">
        <el-form ref="upTableData" :model="upTableData" label-width="10vw">
          <el-form-item label="烘干名称" prop="dryingName">
            <el-input v-model.trim="upTableData.dryingName"></el-input>
          </el-form-item>

          <el-form-item label="采收批次" prop="plantBatchNum">
            <el-select
              @change="changeArtelMan"
              v-model="upTableData.plantBatchNum"
              placeholder="请输入采收批次"
              style="width: 22vw"
            >
              <el-option
                :label="item.recoveryBatch"
                :value="item.id"
                v-for="item in artelNameList"
                :key="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="数量" prop="count">
            <el-input v-model.trim="upTableData.count"></el-input>
          </el-form-item>
          <el-form-item label="数量单位" prop="countUnit">
            <el-input v-model.trim="upTableData.countUnit"></el-input>
          </el-form-item>
          <el-form-item label="记录人" prop="notekeeper">
            <el-select
              @change="changeNoteTaker"
              v-model="upTableData.notekeeper"
              placeholder="请输入记录人"
              style="width: 22vw"
            >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="item in noteNameList"
                :key="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="记录时间" prop="writeTime">
            <el-date-picker
              v-model="upTableData.writeTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="备注" prop="remark	">
            <el-input v-model.trim="upTableData.remark"></el-input>
          </el-form-item>
          <el-form-item size="large">
            <el-button
              class="saveBtn"
              type="primary"
              @click="saveForm('upTableData')"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!-- 编辑 -->
    <el-dialog
      title=""
      :visible.sync="updateForm"
      width="31vw"
      class="addAlameBox"
    >
      <div slot="title" class="header-title" style="position: relative">
        <span class="title-name"></span>
        <span class="title-age">{{ boxPrompt }}</span>
        <span
          style="
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0px;
            font-size: 2.22vh;
          "
        >
        </span>
      </div>
      <div class="addAlaForm">
        <el-form ref="upTableData" :model="upTableData" label-width="10vw">
          <el-form-item class="dsaplaynone" label="烘干名称" prop="dryingName">
            <el-input v-model.trim="upTableData.dryingName"></el-input>
          </el-form-item>
          <el-form-item
            class="dsaplaynone"
            label="采收批次"
            prop="plantBatchNum"
          >
            <el-select
              @change="changeArtelMan"
              v-model="upTableData.plantBatchNum"
              placeholder="请输入采收批次"
              style="width: 22vw"
            >
              <el-option
                :label="item.recoveryBatch"
                :value="item.id"
                v-for="item in artelNameList"
                :key="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="dsaplaynone" label="数量" prop="count">
            <el-input v-model.trim="upTableData.count"></el-input>
          </el-form-item>
          <el-form-item class="dsaplaynone" label="记录人" prop="notekeeper">
            <el-select
              v-model="upTableData.notekeeper"
              placeholder="请输入记录人"
              style="width: 22vw"
            >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="item in noteNameList"
                :key="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="dsaplaynone" label="记录时间" prop="writeTime">
            <el-date-picker
              v-model="upTableData.writeTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="备注" prop="remark	">
            <el-input
              style="width: 20vw; height: 10vh"
              type="textarea"
              v-model.trim="upTableData.remark"
            ></el-input>
          </el-form-item>
          <el-form-item size="large">
            <el-button
              class="saveBtn"
              type="primary"
              @click="saveForm('upTableData')"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      upTableData: {
        dryingName: "",
        plantBatchNum: "",
        count: "",
        countUnit: "",
        notekeeper: "",
        writeTime: "",
        remark: "",
      },
      tableData: [],
      artelNameList: [],
      noteNameList: [],
      boxPrompt: "",
      updateForm: false,
      addupdateForm: false,
      searchStatus: false,
      currentPage: 1,
      multipleSelection: [],
      logisticsForm: {
        name: "",
        region: -1,
      },
      page: 1,
      size: 10,
      listTotal: 0,
      loading: false,
    };
  },
  mounted() {
    this.requrstList();
    this.getCollectionList();
    this.getNotetakerList();
  },
  methods: {
    //请求列表数据
    requrstList() {
      let that = this;
      this.$get("/dryingmanagement/getDryingManagements", {
        page: this.page,
        limit: this.size,
        name: this.logisticsForm.name,
      }).then((res) => {
        if (res.data.state == "success") {
          let m = res.data.datas;
          if (this.page != 1 && m.length == 0) {
            this.page = 1;
            this.requrstList();
          }
          that.tableData = res.data.datas;
          this.listTotal = res.data.count;
        }
        setTimeout(() => {
          this.loading = false;
        }, 500);
      });
    },
    // 获取全部批次列表
    getCollectionList() {
      this.$get("/dryingmanagement/getplantBatchNums", {
        page: 1,
        size: 10000,
      }).then((res) => {
        if (res.data.state == "success") {
          let data = res.data.datas;
          this.artelNameList = data;
        }
      });
    },

    // 获取全部记录人列表
    getNotetakerList() {
      this.$get("/enterpriseManage/querryStaff", {
        page: 1,
        size: 10000,
      }).then((res) => {
        if (res.data.state == "success") {
          let data = res.data.datas;
          this.noteNameList = data;
        }
      });
    },

    changeArtelMan(e) {
      //console.log(e);
      this.upTableData = JSON.parse(JSON.stringify(this.upTableData));
    },

    changeNoteTaker(e) {},

    getFarmName(row) {
      let plantBatchNum = row.plantBatchNum;
      let artelNameList = this.artelNameList;
      for (var x = 0; x < artelNameList.length; x++) {
        if (artelNameList[x].id == plantBatchNum) {
          return artelNameList[x].recoveryBatch;
        }
      }
      //console.log(row.plantBatchNum)
    },

    getTakerName(row) {
      let notekeeper = row.notekeeper;
      let noteNameList = this.noteNameList;
      for (var i = 0; i < noteNameList.length; i++) {
        if (noteNameList[i].id == notekeeper) {
          return noteNameList[i].name;
        }
      }
    },
    // 编辑
    updateClick(param2) {
      //console.log(param2);
      this.boxPrompt = "编辑烘干记录";
      this.updateForm = true;
      this.$nextTick(() => {
        this.upTableData = JSON.parse(JSON.stringify(param2));
        // this.upTableData.plantBatchNum = Number(this.upTableData.plantBatchNum);
        // this.upTableData.notekeeper = Number(this.upTableData.notekeeper);
      });
    },
    // 新增
    addMessage() {
      this.boxPrompt = "新增烘干记录";
      // this.updateForm = true;
      this.addupdateForm = true;
      this.upTableData = {};
    },
    //删除
    delList(item) {
      //console.log(item);
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$get("/dryingmanagement/deleteDryingManagement", {
            id: item.id,
          }).then((res) => {
            //console.log(res);
            if (res.data.state == "success") {
              this.$message.success("删除成功");
              this.requrstList();
            } else {
              this.$message.info("删除失败");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //保存
    saveForm() {
      let that = this;
      this.$refs["upTableData"].validate((valid) => {
        if (valid) {
          let upTableData = this.upTableData;
          let url = "/dryingmanagement/addOrUpdateDryingManagement";
          if (!this.upTableData.dryingName) {
            return this.$message.error("烘干名称不能为空！");
          } else if (!this.upTableData.plantBatchNum) {
            return this.$message.error("采收批次不能为空！");
          } else if (!this.upTableData.count) {
            return this.$message.error("数量不能为空");
          } else if (!this.upTableData.notekeeper) {
            return this.$message.error("记录人不能为空！");
          } else if (!this.upTableData.writeTime) {
            return this.$message.error("记录时间不能为空！");
          } else if (!this.upTableData.remark) {
            return this.$message.error("备注不能为空！");
          } else if (!this.upTableData.countUnit) {
            return this.$message.error("数量不能为空");
          }
          this.$post(url, upTableData).then((res) => {
            this.updateForm = false;
            this.addupdateForm = false;
            if (res.data.state == "success") {
              if (this.title == "新增烘干记录") {
                this.$message.success("添加成功");
              } else {
                this.$message.success("编辑成功");
              }
              this.requrstList();
            } else {
              this.$message.error("添加失败！");
            }
          });
        } else {
          //console.log("error submit!!");
          return false;
        }
      });
    },
    //刷新
    refresh() {
      this.loading = true;
      if (!this.searchStatus) {
        this.requrstList();
      } else {
        this.search();
      }
    },
    //搜索
    search() {
      let name = this.logisticsForm.name;
      this.page = 1;
      //   let level = this.logisticsForm.region;
      //   if (level == -1) level = '';
      this.loading = true;
      this.searchStatus = true;
      if (name == "" || !name) {
        this.searchStatus = false;
      }
      this.requrstList(name);
    },
    //重置
    removeForm() {
      this.loading = true;
      this.logisticsForm.name = "";
      this.logisticsForm.region = -1;
      this.requrstList();
    },

    handleSizeChange(val) {
      this.size = val;
      this.requrstList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.requrstList();
    },
  },
};
</script>

<style scoped lang="less">
@import "@/style/newBackTable.less";
.operation {
  display: flex;
  justify-content: center;
}
.titles {
  border-bottom: 1px #999 solid;
  padding-bottom: 10px;
  font-size: 1.5vh;
  font-weight: bold;
}
.title {
  margin-top: 1vh;
}
.pagesizeBox {
  margin-top: 0;
}
// .dsaplaynone {
//   display: none !important;
// }
</style>
